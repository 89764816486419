<template>
  <div>
    <EmptySpace></EmptySpace>
  </div>
</template>

<script>
import EmptySpace from '@/components/placeholder/EmptySpace'
export default {
  name: 'ViewOrganizationUserOperationalAreas',
  components: {
    EmptySpace,
  },
  data() {
    return {
      org_user: {},
    }
  },
  computed: {
    id() {
      return this.$route.params.id
    },
  },
  async created() {},
}
</script>
